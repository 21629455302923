import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: $setup.locale }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(_component_a_modal, {
        visible: $setup.visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (($setup.visible) = $event)),
        title: "方盒子",
        closable: false,
        maskClosable: false
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: $setup.handleOk
          }, {
            default: _withCtx(() => [
              _createTextVNode("开始使用")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createTextVNode(" 欢迎使用方盒子页面，下面将加入全屏模式，【F11】或【ESC】退出。 ")
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _: 1
  }, 8, ["locale"]))
}