import { getCategoryList, getApplyList, getVersionQuery } from "@/api/api";
import { any } from "vue-types";

// 设置
export function setItem(key: string, data: any): boolean {
    const p = JSON.stringify(data);
    localStorage.setItem(key, p)
    return true
}

// 获取
export function getItem(key: string): any {
    const result = localStorage.getItem(key)
    if (!result) {
        return null
    }
    const p = JSON.parse(result)
    return p;
}

// 删除
export function removeItem(key: string): boolean {
    localStorage.removeItem(key)
    return true
}

// 清空全部
export function clearAll(): boolean {
    localStorage.clear()
    return true
}


// 存储key枚举
export enum keys {
    // 我的应用
    MyApplocat = "my-applocat",
    SearchType = "search-type",
    // 类目
    CategoryList = "category-list",
    // 应用
    ApplyList = "apply-list"
}

// 缓存类目
export function getCategory(): Promise<any> {
    return new Promise((resolve, reject) => {
        const list = getItem(keys.CategoryList)
        if (list) {
            return resolve(list)
        }
        getCategoryList().then((res: any) => {
            if (!res) {
                return reject()
            }
            const data = res.data
            if (data.code != 200) {
                return reject()
            }
            const result = data.result
            setItem(keys.CategoryList, result)
            return resolve(result);
        })
    });
}

// 存储应用有分类区分
export function getApply(type: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const obj = getItem(keys.ApplyList);
        if (obj && obj[type]) {
            return resolve(obj[type])
        }
        const par = {
            type: type == "0" ? "" : type
        }
        getApplyList(par).then((res: any) => {
            if (!res) {
                return reject()
            }
            const data = res.data
            if (data.code != 200) {
                return reject()
            }
            const result = data.result
            let parameter: any = {}
            if (obj) {
                parameter = obj
            }
            parameter[type] = result
            setItem(keys.ApplyList, parameter)
            return resolve(result);
        })
    })
}

/**
 * 版本查询
 */
export function versionQuery() {
    // 查询
    // 请求
    // 品牌
    // 更新

    // 类目
    const catKey = "category_code";
    const categoryCode = getItem(catKey)
    getVersionQuery({ type: catKey }).then((res: any) => {
        if (!res) {
            return
        }
        const data: any = res.data
        if (data.code != 200) {
            return
        }
        const result: string = data.result
        if (categoryCode != result) {
            removeItem(keys.CategoryList)
        }
        setItem(catKey, result)
    })
    // 应用
    const appKey = "apply_code";
    const applyCode = getItem(appKey)
    getVersionQuery({ type: appKey }).then((res: any) => {
        if (!res) {
            return
        }
        const data: any = res.data
        if (data.code != 200) {
            return
        }
        const result: string = data.result
        if (applyCode != result) {
            removeItem(keys.ApplyList)
        }
        setItem(appKey, result)
    })


    
}