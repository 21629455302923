
import { onMounted, onUnmounted, ref } from 'vue';
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import screenfull from 'screenfull';

import "dayjs/locale/zh-cn";

import { versionQuery } from "@/utils/localStorage";
dayjs.locale("zh-cn");


export default {
  name: "App",
  setup() {
    const locale = ref(zhCN)
    const isFullscreen = ref<boolean>(false);
    const visible = ref<boolean>(true);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e: MouseEvent) => {
      console.log(e);
      visible.value = false;
      toggleFullscreen()
    };

    const toggleFullscreen = () => {
      if (screenfull.isEnabled) {
        screenfull.request().then(() => {
          isFullscreen.value = true;
        }).catch(() => {
          // 处理请求全屏失败的情况
        });
      }
    };

    const handleFullscreenChange = () => {
      isFullscreen.value = screenfull.isFullscreen;
      console.log('Fullscreen changed:', screenfull.isFullscreen);
    };
    onMounted(() => {
      // 监听全屏状态变化  
      screenfull.on('change', handleFullscreenChange);
    });

    onUnmounted(() => {
      // 清理事件监听器  
      screenfull.off('change', handleFullscreenChange);
    });

    // 查询报表
    versionQuery();


    return {
      locale,
      isFullscreen,
      toggleFullscreen,
      visible,
      showModal,
      handleOk,
    };
  },
};

