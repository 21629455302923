import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

//引入
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(PerfectScrollbar)
app.use(Antd)


app.mount('#app')
