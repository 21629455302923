// 其中使用 install 的目的在于 ts在main.ts中 
// 不能通过Vue.prototype.$Api这个方式直接调用
//，在全局方法中会说到使用 插件的方式去挂载。
// api.ts
import { Request } from "./request";

/**
 * 获取类目
 * @returns 
 */
export const getCategoryList = () => Request.get("/open/getCategoryList")

/**
 * 获取应用列表
 * @returns 
 */
export const getApplyList = (data: any) => Request.get("/open/getApplyList", data)

/**
 * 查询版本号
 * @param data 
 * @returns 
 */
export const getVersionQuery = (data: any) => Request.get("/open/getVersionQuery", data)

/**
 * 校验URL
 * @param data 
 * @returns 
 */
export const submitURL = (data: any) => Request.post("/open/submitURL", data);


